<template>
  <DocumentsComponent />
</template>

<script>
import DocumentsComponent from '@/components/documents/DocumentsComponent.vue';

export default {
  name: 'Documents',
  components: {
    DocumentsComponent,
  },
};
</script>

